import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const options = {
  // order and from where user language should be detected
  order: ["navigator"],
};
i18n.use(LanguageDetector).init(options);
// the translations 1
// (tip moveto JSON file and import)
const resources = {
  en: {
    translation: {
      ExpiresEnd: "Expiration date",
      cardHolderLabel: "Cardholder Name",
      cardHolderPlaceHolder: "Name on the card",
      cardPanLabel: "Card Number",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Expiration date",
      CardExpiryPlaceholder: "MM/YY",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "One-Time Password",
      Password: "Password",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Amount",
      AmountPlaceholder: "Enter the amount",
      ButtonSubmitLabel: "PAY NOW",
      SelectPaymentMethodLabel: "Select Payment Method",
      SelectPredefineAmountLabel: "Select an Amount",
      ManualAmountLabel: "Use a Different Amount",
      customerCitizenshipCountryCodeLabel: "Citizenship",
      customerCitizenshipCountryCodePlaceHolder: "Country of citizenship",
      billingCountryCodeLabel: "Country",
      billingCountryCodePlaceHolder: "Select Country",
      customerFirstNameLabel: "First name",
      customerFirstNamePlaceHolder: "First name",
      customerLastNameLabel: "Last name",
      customerLastNamePlaceHolder: "Last name",
      billingAddressLine1Label: "Address line 1",
      billingAddressLine1PlaceHolder: "Address line 1",
      billingCityLabel: "City",
      billingCityPlaceHolder: "City",
      billingStateLabel: "State",
      billingStatePlaceHolder: "State",
      billingPostalCodeLabel: "Postal code",
      billingPostalCodePlaceHolder: "Postal code",
      customerDateOfBirthLabel: "Date of birth",
      customerDateOfBirthPlaceHolder: "Date of birth",
      customerEmailLabel: "Email",
      customerEmailPlaceHolder: "Enter your email",
      customerPhoneLabel: "Phone",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "Personal Identification",
      customerPersonalIdPlaceHolder: "Identification number",
      billingAddressLine2Label: "Address line 2",
      billingAddressLine2PlaceHolder: "Address line 2",
      CardNumberRequired: "Card number is required",
      CardNumberInvalid: "Invalid card number",
      CardExpiryDateRequired: "Expire date is required",
      CVVRequired: "Security code is required",
      customerAccountNumberLabel: "Account Number",
      customerAccountNumberPlaceHolder: "Enter your account",
      AmountRequiredLabel: "Amount is required",
      AmountInvalidLabel: "Amount is invalid",
      CancelLabel: "Cancel",
      PhoneNumberRequired: "Phone number is required",
      PhoneNumberInvalid: "Phone number is invalid",
      PaymentMethodNotAvailableLabel: "Payment methods not available",
      ButtonConfirmLabel: "Confirm",
      BASIC_CARD: "Credit Card",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Pay with your bank",
      VOLT: "Pay with your bank",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Pay with your bank",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Crypto deposit",
      ConfirmationMessage: "Please confirm the converted amount.",
      customSelectPlaceHolderpaymentMethod: "Select method",
      customSelectPlaceHolderdocumentType: "Select document type",
      customSelectPlaceHoldercryptoCurrency: "Select crypto currency",
      blockchainValuePlaceHolder: "Select the blockchain value",
      paymentMethod: "Payment Method",
      documentType: "Document type",
      documentNumber: "Document number",
      cryptoCurrency: "Crypto currency",
      accountNumber: "Account number",
      maskedPanMessage: "Amount will be deducted from :",
      endingIn: "ending in",
      descriptionLabel: "Description:",
      invalidCVV: "Invalid CVV",
      invalidOTP: "Invalid OTP",
      OTPRequired: "OTP is required",
      PasswordRequired: "Password is required",
      invalidPassword: "Invalid Password",
      CardExpiryDateInvalid: "Invalid or expired date",
      IN_PROGRESS: "IN PROGRESS",
      CANCELLED: "CANCELLED",
      DECLINED: "DECLINED",
      COMPLETED: "COMPLETED",
      backToShopButtonLabel: "Return to website",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Deposit amount:",
      debitAmountLabel: "Debit amount:",
      validationRequired: "The {{fieldName}} field is required",
      validationMaxLength: "The maximum length is {{maxLength}} characters",
      cryptoWarningMessage_1:
        "If you send any other amount, the system will accept it anyway!",
      sendAmountInToAddress:
        "Send {{amount}} in one payment to the following address: {{address}}",
      copyToClipboard: "Copy address to clipboard",
      copied: "Copied",
      scanAddressOr: "Scan the wallet address or",
      amountToPay: "To pay",
      commissionLabel: "Commission:",
      walletDepositMessage:
        "Please proceed with deposit using the following address:",
      ButtonConfirmCascadingLabel: "Try again using another provider",
      ButtonConfirmCascadingTimeoutLabel: "Will try another provider in ",
      cascadingInstructions:
        "Your transaction was declined, but don't worry as we have another provider to try with. Please, do not close this page.",
      errorCode:
        "Dear Customer, unfortunately, there’s no available payment option for this scenario. Please make sure the following params are supported: Card Type, BIN Country, Amount and Currency Please contact the merchant or try to use another card.",
      TERMINAL_NOT_FOUND: `Dear Customer, Unfortunately, there is no available payment option for this scenario. Please use another payment method or another card.`,
      saveCardLabel: "Do you want to save the card?",
      addNewCard: "Add new card",
      accept: "Accept",
      validationInvalidCharactersInName:
        "Please use at least two Latin symbols",
      validationExactlyTwoWordsRequiredInName:
        "The cardholder's name must consist of two to four words, each containing at least two letters.",
      validationNotBlank: "The {{fieldName}} field cannot be blank",
      receiptBtn: "Get receipt",
      progress:
        "Please wait, processing your transaction. Do not close this page",
      verifyCard: "Verify Card",
      withdraw: "Withdraw",
    },
  },
  ru: {
    translation: {
      ExpiresEnd: "Срок действия",
      cardHolderLabel: "Имя на карте",
      cardHolderPlaceHolder: "Имя на карте",
      cardPanLabel: "Номер карты",
      cardPanPlaceHolder: "Номер карты",
      CardExpiryLable: "ММ/ГГ",
      CardExpiryPlaceholder: "ММ/ГГ",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "One-Time Password",
      Password: "Password",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Сумма",
      AmountPlaceholder: "Сумма",
      ButtonSubmitLabel: "Оплатить",
      SelectPaymentMethodLabel: "Выберите способ оплаты",
      SelectPredefineAmountLabel: "Выбрать сумму",
      ManualAmountLabel: "Выбрать другую сумму",
      customerCitizenshipCountryCodeLabel: "Гражданство",
      customerCitizenshipCountryCodePlaceHolder: "Гражданство",
      billingCountryCodeLabel: "Страна",
      billingCountryCodePlaceHolder: "Выберите страну",
      customerFirstNameLabel: "Имя",
      customerFirstNamePlaceHolder: "Имя",
      customerLastNameLabel: "Фамилия",
      customerLastNamePlaceHolder: "Фамилия",
      customerPersonalIdLabel: "Номер паспорта",
      customerPersonalIdPlaceHolder: "Номер паспорта",
      billingAddressLine1Label: "Адрес",
      billingAddressLine1PlaceHolder: "Адрес",
      billingCityLabel: "Город",
      billingCityPlaceHolder: "Город",
      billingStateLabel: "Регион",
      billingStatePlaceHolder: "Регион",
      billingPostalCodeLabel: "Почтовый индекс",
      billingPostalCodePlaceHolder: "Почтовый индекс",
      customerDateOfBirthLabel: "Дата рождения",
      customerDateOfBirthPlaceHolder: "Дата рождения",
      customerEmailLabel: "Email",
      customerEmailPlaceHolder: "Email",
      customerPhoneLabel: "Телефон",
      customerPhonePlaceHolder: "+51 1234567",
      billingAddressLine2Label: "Адрес, строка 2",
      billingAddressLine2PlaceHolder: "Адрес, строка 2",
      customerAccountNumberLabel: "Номер счета",
      customerAccountNumberPlaceHolder: "Номер счета",
      AmountRequiredLabel: "Не выбрана сумма",
      AmountInvalidLabel: "Неверная сумма",
      CancelLabel: "Отменить",
      CardNumberRequired: "Номер карты обязателен",
      PhoneNumberRequired: "Не выбран номер телефона",
      CardNumberInvalid: "Не верный номер карты",
      CardExpiryDateRequired: "Укажите дату истечения срока действия",
      CVVRequired: "Требуется код безопасности",
      PhoneNumberInvalid: "Неверный номер телефона",
      PaymentMethodNotAvailableLabel: "Нет доступных способов платежа",
      ButtonConfirmLabel: "Подтвердить",
      BASIC_CARD: "Credit Card",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      TRUSTPAYMENTS: "TrustPayments",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Платить в своем банке",
      VOLT: "Платить в своем банке",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Платить в своем банке",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Crypto депозит",
      ConfirmationMessage: "Пожалуйста, подтвердите сумму после конвертации.",
      customSelectPlaceHolderpaymentMethod: "Выберите метод",
      customSelectPlaceHolderdocumentType: "Выберите тип документа",
      customSelectPlaceHoldercryptoCurrency: "Выберите крипто валюту",
      blockchainValuePlaceHolder: "Выберите значение блокчейна",
      paymentMethod: "Способ платежа",
      documentType: "Тип документа",
      documentNumber: "Номер документа",
      cryptoCurrency: "Крипто валюта",
      accountNumber: "Номер счета",
      maskedPanMessage: "Сумма будет списана с карты:",
      endingIn: "заканчивается на",
      descriptionLabel: "Описание:",
      invalidCVV: "Неверный CVV",
      invalidOTP: "Неверный OTP",
      OTPRequired: "OTP is required",
      PasswordRequired: "Password is required",
      invalidPassword: "Invalid Password",
      CardExpiryDateInvalid: "Неверный срок дейстия",
      IN_PROGRESS: "ОБРАБАТЫВАЕТСЯ",
      CANCELLED: "ОТМЕНЕН",
      DECLINED: "ОТКЛОНЕН",
      COMPLETED: "УСПЕШЕН",
      backToShopButtonLabel: "Вернуться на сайт",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Сумма к зачислению:",
      debitAmountLabel: "Сумма списания:",
      validationRequired: "Поле {{fieldName}} обязательно",
      validationMaxLength: "Максимальная длина {{maxLength}} символов",
      cryptoWarningMessage_1:
        "Если Вы отправите другую сумму, система все равно обработает оплату!",
      sendAmountInToAddress:
        "Отправьте {{amount}} одним платежом на следующий адрес: {{address}}",
      copyToClipboard: "Скопируйте адрес",
      copied: "Скопировано",
      scanAddressOr: "Сканируйте адрес кошелька или",
      amountToPay: "Платить",
      commissionLabel: "Комиссия:",
      walletDepositMessage:
        "Пожалуйста, продолжите внесение депозита, используя следующий адрес:",
      ButtonConfirmCascadingLabel:
        "Попробуйте еще раз, используя другого провайдера",
      ButtonConfirmCascadingTimeoutLabel: "Попробую другого провайдера через",
      cascadingInstructions:
        "Ваша транзакция была отклонена, но не волнуйтесь, у нас есть другой провайдер для попытки. Пожалуйста, не закрывайте эту страницу.",
      TERMINAL_NOT_FOUND: `Уважаемый клиент, к сожалению, для данного сценария нет доступного способа оплаты. Пожалуйста, используйте другой метод оплаты или другую карту.`,
      saveCardLabel: "Сохранить карту",
      addNewCard: "добавить новую карту",
      accept: "Принять",
      validationInvalidCharactersInName:
        "Убедитесь, что используются минимум два латинских символа.",
      validationExactlyTwoWordsRequiredInName:
        "Имя владельца карты должно состоять из двух-четырех слов, содержащих не менее двух букв.",
      validationNotBlank: "Поле {{fieldName}} не может быть пустым.",
      progress:
        "Пожалуйста, подождите, ваша транзакция в обработке. Не закрывайте эту страницу.",
      verifyCard: "Подтвердить карту",
      withdraw: "Вывести средства",
    },
  },
  kk: {
    translation: {
      ExpiresEnd: "Срок действия",
      cardHolderLabel: "Имя на карте",
      cardHolderPlaceHolder: "Имя на карте",
      cardPanLabel: "Номер карты",
      cardPanPlaceHolder: "Номер карты",
      CardExpiryLable: "ММ/ГГ",
      CardExpiryPlaceholder: "ММ/ГГ",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "One-Time Password",
      Password: "Password",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Сумма",
      AmountPlaceholder: "Сумма",
      ButtonSubmitLabel: "Оплатить",
      SelectPaymentMethodLabel: "Выберите способ оплаты",
      SelectPredefineAmountLabel: "Выбрать сумму",
      ManualAmountLabel: "Выбрать другую сумму",
      customerCitizenshipCountryCodeLabel: "Гражданство",
      customerCitizenshipCountryCodePlaceHolder: "Гражданство",
      billingCountryCodeLabel: "Страна",
      billingCountryCodePlaceHolder: "Выберите страну",
      customerFirstNameLabel: "Имя",
      customerFirstNamePlaceHolder: "Имя",
      customerLastNameLabel: "Фамилия",
      customerLastNamePlaceHolder: "Фамилия",
      customerPersonalIdLabel: "Номер паспорта",
      customerPersonalIdPlaceHolder: "Номер паспорта",
      billingAddressLine1Label: "Адрес, строка 2",
      billingAddressLine1PlaceHolder: "Адрес",
      billingCityLabel: "Город",
      billingCityPlaceHolder: "Город",
      billingStateLabel: "Регион",
      billingStatePlaceHolder: "Регион",
      billingPostalCodeLabel: "Почтовый индекс",
      billingPostalCodePlaceHolder: "Почтовый индекс",
      customerDateOfBirthLabel: "Дата рождения",
      customerDateOfBirthPlaceHolder: "Дата рождения",
      customerEmailLabel: "Email",
      customerEmailPlaceHolder: "Email",
      customerPhoneLabel: "Телефон",
      customerPhonePlaceHolder: "+51 1234567",
      billingAddressLine2Label: "Адрес, строка 2",
      billingAddressLine2PlaceHolder: "Адрес, строка 2",
      customerAccountNumberLabel: "Номер счета",
      customerAccountNumberPlaceHolder: "Номер счета",
      AmountRequiredLabel: "Не выбрана сумма",
      AmountInvalidLabel: "Неверная сумма",
      CancelLabel: "Отменить",
      CardNumberRequired: "Номер карты обязателен",
      PhoneNumberRequired: "Не выбран номер телефона",
      CardNumberInvalid: "Не верный номер карты",
      CardExpiryDateRequired: "Укажите дату истечения срока действия",
      CVVRequired: "Требуется код безопасности",
      PhoneNumberInvalid: "Неверный номер телефона",
      PaymentMethodNotAvailableLabel: "Нет доступных способов платежа",
      ButtonConfirmLabel: "Подтвердить",
      BASIC_CARD: "Credit Card",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      TRUSTPAYMENTS: "TrustPayments",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Платить в своем банке",
      VOLT: "Платить в своем банке",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Платить в своем банке",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Crypto депозит",
      ConfirmationMessage: "Пожалуйста, подтвердите сумму после конвертации.",
      customSelectPlaceHolderpaymentMethod: "Выберите метод",
      customSelectPlaceHolderdocumentType: "Выберите тип документа",
      customSelectPlaceHoldercryptoCurrency: "Выберите крипто валюту",
      blockchainValuePlaceHolder: "Выберите значение блокчейна",
      paymentMethod: "Способ платежа",
      documentType: "Тип документа",
      documentNumber: "Номер документа",
      cryptoCurrency: "Крипто валюта",
      accountNumber: "Номер счета",
      maskedPanMessage: "Сумма будет списана с карты:",
      endingIn: "заканчивается на",
      descriptionLabel: "Описание:",
      invalidCVV: "Неверный CVV",
      invalidOTP: "Неверный OTP",
      CardExpiryDateInvalid: "Неверный срок дейстия",
      IN_PROGRESS: "ОБРАБАТЫВАЕТСЯ",
      CANCELLED: "ОТМЕНЕН",
      DECLINED: "ОТКЛОНЕН",
      COMPLETED: "УСПЕШЕН",
      backToShopButtonLabel: "Вернуться на сайт",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Сумма к зачислению:",
      debitAmountLabel: "Сумма списания:",
      validationRequired: "Поле {{fieldName}} обязательно",
      validationMaxLength: "Максимальная длина {{maxLength}} символов",
      cryptoWarningMessage_1:
        "Если Вы отправите другую сумму, система все равно обработает оплату!",
      sendAmountInToAddress:
        "Отправьте {{amount}} одним платежом на следующий адрес: {{address}}",
      copyToClipboard: "Скопируйте адрес",
      copied: "Скопировано",
      scanAddressOr: "Сканируйте адрес кошелька или",
      amountToPay: "Платить",
      commissionLabel: "Комиссия:",
      walletDepositMessage:
        "Пожалуйста, продолжите внесение депозита, используя следующий адрес:",
      ButtonConfirmCascadingLabel:
        "Попробуйте еще раз, используя другого провайдера",
      ButtonConfirmCascadingTimeoutLabel: "Попробую другого провайдера через",
      cascadingInstructions:
        "Ваша транзакция была отклонена, но не волнуйтесь, у нас есть другой провайдер для попытки. Пожалуйста, не закрывайте эту страницу.",
      TERMINAL_NOT_FOUND: `Уважаемый клиент, к сожалению, для данного сценария нет доступного способа оплаты. Пожалуйста, используйте другой метод оплаты или другую карту.`,
      saveCardLabel: "Сохранить карту",
      addNewCard: "добавить новую карту",
      accept: "Принять",
      validationInvalidCharactersInName:
        "Убедитесь, что используются минимум два латинских символа.",
      validationExactlyTwoWordsRequiredInName:
        "Имя владельца карты должно состоять из двух-четырех слов, содержащих не менее двух букв.",
      validationNotBlank: "Поле {{fieldName}} не может быть пустым.",
      progress:
        "Пожалуйста, подождите, ваша транзакция в обработке. Не закрывайте эту страницу.",
      verifyCard: "Подтвердить карту",
      withdraw: "Вывести средства",
    },
  },
  it: {
    translation: {
      ExpiresEnd: "Scade Fine",
      cardHolderLabel: "Nome titolare",
      cardHolderPlaceHolder: "Nome sulla carta",
      cardPanLabel: "Numero carta",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Data di scadenza",
      CardExpiryPlaceholder: "MM/AA",
      CardSecurityCodeLabel: "Codice di sicurezza",
      PasswordLabel: "One-Time Password",
      Password: "Password",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Importo",
      AmountPlaceholder: "Digita l'importo",
      ButtonSubmitLabel: "Paga ora",
      SelectPaymentMethodLabel: "Seleziona un metodo di pagamento",
      SelectPredefineAmountLabel: "Seleziona un importo",
      ManualAmountLabel: "Usa un importo diverso",
      customerCitizenshipCountryCodeLabel: "Cittadinanza",
      customerCitizenshipCountryCodePlaceHolder: "Paese di cittadinanza",
      billingCountryCodeLabel: "Stato",
      billingCountryCodePlaceHolder: "Seleziona Stato",
      customerFirstNameLabel: "Nome",
      customerFirstNamePlaceHolder: "Nome",
      customerLastNameLabel: "Cognome",
      customerLastNamePlaceHolder: "Cognome",
      billingAddressLine1Label: "Indirizzo riga 1",
      billingAddressLine1PlaceHolder: "Indirizzo riga 1",
      billingCityLabel: "Città",
      billingCityPlaceHolder: "Città",
      billingStateLabel: "Stato",
      billingStatePlaceHolder: "Stato",
      billingPostalCodeLabel: "Codice postale",
      billingPostalCodePlaceHolder: "Codice postale",
      customerDateOfBirthLabel: "Data di nascita",
      customerDateOfBirthPlaceHolder: "Data di nascita",
      customerEmailLabel: "Indirizzo e-mail",
      customerEmailPlaceHolder: "mail@mail.com",
      customerPhoneLabel: "Numero di telefono",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "Identificativo personale",
      customerPersonalIdPlaceHolder: "Numero identificativo",
      billingAddressLine2Label: "Indirizzo riga 2",
      billingAddressLine2PlaceHolder: "Indirizzo riga 2",
      CardNumberRequired: "Inserisci il numero della carta",
      CardNumberInvalid: "Numero carta non valido",
      CardExpiryDateRequired: "Inserisci la data di scadenza",
      CVVRequired: "Inserisci il codice di sicurezza",
      customerAccountNumberLabel: "Numero account",
      customerAccountNumberPlaceHolder: "Digita il tuo account",
      AmountRequiredLabel: "Inserisci l'importo",
      AmountInvalidLabel: "L'importo non è valido",
      CancelLabel: "Annulla",
      PhoneNumberRequired: "Inserisci il numero di telefono",
      PhoneNumberInvalid: "Il numero di telefono non è valido",
      PaymentMethodNotAvailableLabel: "Metodo di pagamento non disponibile",
      ButtonConfirmLabel: "Conferma",
      BASIC_CARD: "Carta di credito",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      TRUSTPAYMENTS: "TrustPayments",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Pay with your bank",
      VOLT: "Pay with your bank",
      B2BINPAY: "B2BinPay",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Pay with your bank",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Deposito di criptovalute",
      ConfirmationMessage: "Conferma l'importo convertito.",
      customSelectPlaceHolderpaymentMethod: "Seleziona metodo",
      customSelectPlaceHolderdocumentType: "Seleziona il tipo di documento",
      customSelectPlaceHoldercryptoCurrency: "Select crypto currency",
      blockchainValuePlaceHolder: "Seleziona il valore della blockchain",
      paymentMethod: "Metodo di pagamento",
      documentType: "Tipo di documento",
      documentNumber: "Numero documento",
      cryptoCurrency: "Crypto currency",
      accountNumber: "Numero account",
      maskedPanMessage: "L'importo verrà detratto da:",
      endingIn: "termina con",
      descriptionLabel: "Descrizione:",
      invalidCVV: "CVV non valido",
      invalidOTP: "OTP non valido",
      OTPRequired: "OTP is required",
      PasswordRequired: "Password is required",
      invalidPassword: "Invalid Password",
      CardExpiryDateInvalid: "Data di scadenza non valida",
      IN_PROGRESS: "IN CORSO",
      CANCELLED: "ANNULLATA",
      DECLINED: "DECLINATA",
      COMPLETED: "COMPLETATO",
      backToShopButtonLabel: "Ritorno al sito web",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Deposit amount:",
      debitAmountLabel: "Debit amount:",
      validationRequired: "Il campo {{fieldName}} è obbligatorio",
      validationMaxLength: "La lunghezza massima è {{maxLength}} caratteri",
      cryptoWarningMessage_1:
        "Se invii un altro importo, il sistema lo accetterà comunque!",
      sendAmountInToAddress:
        "Invia {{amount}} in un unico pagamento al seguente indirizzo: {{address}}",
      copyToClipboard: "Copia l'indirizzo",
      copied: "copiato",
      scanAddressOr: "Scansiona l'indirizzo del portafoglio o",
      amountToPay: "Pagare",
      commissionLabel: "Commissione:",
      walletDepositMessage:
        "Si prega di procedere con il deposito utilizzando il seguente indirizzo:",
      ButtonConfirmCascadingLabel: "Riprova utilizzando un altro provider",
      ButtonConfirmCascadingTimeoutLabel: "Proverò con un altro provider",
      cascadingInstructions:
        "La tua transazione è stata declinata, ma non preoccuparti perché abbiamo un altro fornitore da provare. Per favore, non chiudere questa pagina.",
      TERMINAL_NOT_FOUND: `Gentile Cliente, purtroppo non è disponibile un'opzione di pagamento per questo scenario. Si prega di utilizzare un altro metodo di pagamento o un'altra carta.`,
      saveCardLabel: "Salva questa carta",
      addNewCard: "aggiungi una nuova carta",
      accept: "Accettare",
      validationInvalidCharactersInName:
        "Si prega di utilizzare almeno due simboli latini.",
      validationExactlyTwoWordsRequiredInName:
        "Il nome del titolare della carta deve essere composto da due o quattro parole con almeno due lettere.",
      validationNotBlank: "Il campo {{fieldName}} non può essere vuoto.",
      progress:
        "Per favore, attendi, elaborazione della tua transazione. Non chiudere questa pagina.",
      verifyCard: "Verifica della carta",
      withdraw: "Prelievo di fondi",
    },
  },
  fr: {
    translation: {
      ExpiresEnd: "Data di scadenza della",
      cardHolderLabel: "Titulaire",
      cardHolderPlaceHolder: "Nom sur la carte",
      cardPanLabel: "Numéro de carte",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Expiration",
      CardExpiryPlaceholder: "MM/AA",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "One-Time Password",
      Password: "Password",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Montant",
      AmountPlaceholder: "Entrez le montant",
      ButtonSubmitLabel: "Payer maintenant",
      SelectPaymentMethodLabel: "Choisissez le mode de paiement",
      SelectPredefineAmountLabel: "Choisissez un montant",
      ManualAmountLabel: "Utiliser un montant différent",
      customerCitizenshipCountryCodeLabel: "Nationalité",
      customerCitizenshipCountryCodePlaceHolder: "Pays de nationalité",
      billingCountryCodeLabel: "Pays",
      billingCountryCodePlaceHolder: "Choisissez le pays",
      customerFirstNameLabel: "Prénom",
      customerFirstNamePlaceHolder: "Prénom",
      customerLastNameLabel: "Nom de famille",
      customerLastNamePlaceHolder: "Nom de famille",
      billingAddressLine1Label: "Adresse ligne 1",
      billingAddressLine1PlaceHolder: "Adresse ligne 1",
      billingCityLabel: "Ville",
      billingCityPlaceHolder: "Ville",
      billingStateLabel: "État",
      billingStatePlaceHolder: "État",
      billingPostalCodeLabel: "Code postal",
      billingPostalCodePlaceHolder: "Code postal",
      customerDateOfBirthLabel: "Date de naissance",
      customerDateOfBirthPlaceHolder: "Date de naissance",
      customerEmailLabel: "Email",
      customerEmailPlaceHolder: "mail@mail.com",
      customerPhoneLabel: "Numéro de téléphone",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "PIN",
      customerPersonalIdPlaceHolder: "Code PIN",
      billingAddressLine2Label: "Adresse ligne 2",
      billingAddressLine2PlaceHolder: "Adresse ligne 2",
      CardNumberRequired: "Un numéro de carte est requis",
      CardNumberInvalid: "NUméro de carte invalide",
      CardExpiryDateRequired: "Une date d'expiration est requise",
      CVVRequired: "Un code de sécurité est requis",
      customerAccountNumberLabel: "Numéro de compte",
      customerAccountNumberPlaceHolder: "Entrez votre numéro de compte",
      AmountRequiredLabel: "Un montant est requis",
      AmountInvalidLabel: "Le montant est invalide",
      CancelLabel: "Annuler",
      PhoneNumberRequired: "Un numéro de téléphone est requis",
      PhoneNumberInvalid: "Le numéro de téléphone est invalide",
      PaymentMethodNotAvailableLabel: "Mode de paiement non disponible",
      ButtonConfirmLabel: "Confirmer",
      BASIC_CARD: "Carte de crédit",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      TRUSTPAYMENTS: "TrustPayments",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Pay with your bank",
      VOLT: "Pay with your bank",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Pay with your bank",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Dépôt de crypto",
      ConfirmationMessage: "Veuillez confirmer le montant converti.",
      customSelectPlaceHolderpaymentMethod: "Choisissez le mode de paiement",
      customSelectPlaceHolderdocumentType: "Choisissez le type de document",
      customSelectPlaceHoldercryptoCurrency: "Select crypto currency",
      blockchainValuePlaceHolder: "Sélectionnez la valeur de la blockchain",
      paymentMethod: "Mode de paiement",
      documentType: "Type de document",
      documentNumber: "Numéro du document",
      cryptoCurrency: "Crypto currency",
      accountNumber: "Numéro de compte",
      maskedPanMessage: "Le montant sera déduit de :",
      endingIn: "finissant en",
      descriptionLabel: "Description:",
      invalidCVV: "CVV invalide",
      invalidOTP: "OTP invalide",
      OTPRequired: "OTP is required",
      PasswordRequired: "Password is required",
      invalidPassword: "Invalid Password",
      CardExpiryDateInvalid: "Date d'expiration invalide",
      IN_PROGRESS: "EN COURS",
      CANCELLED: "ANNULÉ",
      DECLINED: "REFUSÉE",
      COMPLETED: "COMPLÉTÉ",
      backToShopButtonLabel: "Retourner au site web",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Deposit amount:",
      debitAmountLabel: "Debit amount:",
      validationRequired: "Le champ {{fieldName}} est obligatoire",
      validationMaxLength:
        "La longueur maximale est de {{maxLength}} caractères",
      cryptoWarningMessage_1:
        "Si vous envoyez un autre montant, le système l'acceptera quand même !",
      sendAmountInToAddress:
        "Envoyez {{amount}} en un seul paiement à l'adresse suivante : {{address}}",
      copyToClipboard: "Copier l'adresse dans le presse-papiers",
      copied: "Copié",
      scanAddressOr: "Scannez l'adresse du portefeuille ou",
      amountToPay: "Payer",
      commissionLabel: "Commission:",
      walletDepositMessage: "Veuillez procéder au dépôt à l'adresse suivante:",
      ButtonConfirmCascadingLabel:
        "Réessayez en utilisant un autre fournisseur",
      ButtonConfirmCascadingTimeoutLabel:
        "Je vais essayer un autre fournisseur dans",
      cascadingInstructions:
        "Votre transaction a été refusée, mais ne vous inquiétez pas car nous avons un autre fournisseur à essayer. S'il vous plaît, ne fermez pas cette page.",
      TERMINAL_NOT_FOUND: `Cher client, malheureusement, il n'y a pas d'option de paiement disponible pour ce scénario. Veuillez utiliser un autre mode de paiement ou une autre carte.`,
      saveCardLabel: "Enregistrer cette carte",
      addNewCard: "ajouter une nouvelle carte",
      accept: "J'accepte",
      validationInvalidCharactersInName:
        "Veuillez utiliser au moins deux symboles latinsю",
      validationExactlyTwoWordsRequiredInName:
        "Le nom du titulaire de la carte doit être composé de deux à quatre mots comportant au moins deux lettres.",
      validationNotBlank: "Le champ {{fieldName}} ne peut pas être vide.",
      progress:
        "Veuillez patienter, traitement de votre transaction. Ne fermez pas cette page.",
      verifyCard: "Vérifier la carte",
      withdraw: "Retrait de fonds",
    },
  },
  es: {
    translation: {
      ExpiresEnd: "Fecha de caducidad",
      cardHolderLabel: "Titular de la tarjeta",
      cardHolderPlaceHolder: "Nombre en la tarjeta",
      cardPanLabel: "Número de tarjeta",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Mes/Año",
      CardExpiryPlaceholder: "MM/AA",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "One-Time Password",
      Password: "Password",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Importe",
      AmountPlaceholder: "Insertar importe",
      ButtonSubmitLabel: "Paga ahora",
      SelectPaymentMethodLabel: "Seleccionar método de pago",
      SelectPredefineAmountLabel: "Seleccionar el importe",
      ManualAmountLabel: "Elige otro importe",
      customerCitizenshipCountryCodeLabel: "Nacionalidad",
      customerCitizenshipCountryCodePlaceHolder: "País de nacionalidad",
      billingCountryCodeLabel: "País",
      billingCountryCodePlaceHolder: "Seleccionar país",
      customerFirstNameLabel: "Nombre",
      customerFirstNamePlaceHolder: "Nombre",
      customerLastNameLabel: "Apellido",
      customerLastNamePlaceHolder: "Apellido",
      billingAddressLine1Label: "Dirección línea 1",
      billingAddressLine1PlaceHolder: "Dirección línea 1",
      billingCityLabel: "Ciudad",
      billingCityPlaceHolder: "Ciudad",
      billingStateLabel: "Provincia",
      billingStatePlaceHolder: "Provincia",
      billingPostalCodeLabel: "Código postal",
      billingPostalCodePlaceHolder: "Código postal",
      customerDateOfBirthLabel: "Fecha de nacimiento",
      customerDateOfBirthPlaceHolder: "Fecha de nacimiento",
      customerEmailLabel: "Email",
      customerEmailPlaceHolder: "Insertar email",
      customerPhoneLabel: "Teléfono",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "DNI/NIE",
      customerPersonalIdPlaceHolder: "DNI/NIE",
      billingAddressLine2Label: "Dirección línea 2",
      billingAddressLine2PlaceHolder: "Dirección línea 2",
      CardNumberRequired: "Número de tarjeta es obligatorio",
      CardNumberInvalid: "Número de tarjeta no válido",
      CardExpiryDateRequired: "Fecha de caducidad obligatorio",
      CVVRequired: "Código de seguridad obligatorio",
      customerAccountNumberLabel: "Número de cuenta",
      customerAccountNumberPlaceHolder: "Insertar número de cuenta",
      AmountRequiredLabel: "Importe obligatorio",
      AmountInvalidLabel: "Importe no es válido",
      CancelLabel: "Cancelar",
      PhoneNumberRequired: "Número de teléfono obligatorio",
      PhoneNumberInvalid: "Número de teléfono no es válido",
      PaymentMethodNotAvailableLabel: "Métodos de pago no disponibles",
      ButtonConfirmLabel: "Confirmar",
      BASIC_CARD: "Tarjeta de crédito",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Transferencia bancaria",
      VOLT: "Transferencia bancaria",
      B2BINPAY: "B2BinPay (Criptomoneda)",
      B2BINPAYV2: "B2BinPay (Criptomoneda)",
      CLICK: "Transferencia bancaria",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Criptomoneda",
      ConfirmationMessage: "Por favor, confirme el importe convertido.",
      customSelectPlaceHolderpaymentMethod: "Seleccionar método",
      customSelectPlaceHolderdocumentType: "Seleccionar tipo de documento",
      customSelectPlaceHoldercryptoCurrency: "Seleccionar criptomoneda",
      blockchainValuePlaceHolder: "Seleccione el valor de la cadena de bloques",
      paymentMethod: "Método de pago",
      documentType: "Tipo de documento",
      documentNumber: "Número de documento",
      cryptoCurrency: "Criptomoneda",
      accountNumber: "Número de cuenta",
      maskedPanMessage: "Importe será deducido de :",
      endingIn: "que acaba con",
      descriptionLabel: "Descripción:",
      invalidCVV: "CVV no válido",
      invalidOTP: "OTP no válido",
      OTPRequired: "OTP is required",
      PasswordRequired: "Password is required",
      invalidPassword: "Invalid Password",
      CardExpiryDateInvalid: "Fecha no válida o caducada",
      IN_PROGRESS: "IN PROGRESS",
      CANCELLED: "CANCELLED",
      DECLINED: "DECLINED",
      COMPLETED: "COMPLETED",
      backToShopButtonLabel: "Volver al sitio web",
      minAmountLabel: "Mín:",
      maxAmountLabel: "Máx:",
      depositAmountLabel: "Importe de depósito:",
      debitAmountLabel: "Importe de débito:",
      validationRequired: "El campo {{fieldName}} es obligatorio",
      validationMaxLength: "La longitud máxima es de {{maxLength}} carácteres",
      cryptoWarningMessage_1:
        "Si envia otra cantidad, ¡el sistema lo aceptará igualmente!",
      sendAmountInToAddress:
        "Envíe {{amount}} en una sóla transacción a la siguiente dirección: {{address}}",
      copyToClipboard: "Copiar dirección al portapapeles",
      copied: "Copiado",
      scanAddressOr: "Escánear dirección de billetera o",
      amountToPay: "Para pagar",
      commissionLabel: "Comisión:",
      walletDepositMessage: "Proceda con el depósito a la siguiente dirección:",
      ButtonConfirmCascadingLabel: "Pruebe de nuevo con otro proveedor",
      ButtonConfirmCascadingTimeoutLabel: "Intentará con otro proveedor en ",
      cascadingInstructions:
        "Su transacción fue rechazada, pero no se preocupe ya que tenemos otro proveedor para intentar. Por favor, no cierre esta página.",
      errorCode:
        "Estimado cliente, desafortunadamente, no hay una opción de pago disponible para esta situación. Asegúrese que los detalles conformen con los siguientes parámetros: tipo de tarjeta, país de tarjeta, importe y moneda. Póngase en contacto con el banco o intente de nuevo con otra tarjeta.",
      TERMINAL_NOT_FOUND: `Estimado cliente, lamentablemente, no hay una opción de pago disponible para este escenario. Por favor, use otro método de pago u otra tarjeta.`,
      saveCardLabel: "Guarde esta tarjeta",
      addNewCard: "añadir nueva tarjeta",
      accept: "Aceptar",
      validationInvalidCharactersInName:
        "Utilice al menos dos símbolos latinos.",
      validationExactlyTwoWordsRequiredInName:
        "El nombre del titular de la tarjeta debe constar de dos a cuatro palabras con al menos dos letras.",
      validationNotBlank: "El campo {{fieldName}} no puede estar en blanco.",
      progress:
        "Por favor, espere, procesando su transacción. No cierre esta página.",
      verifyCard: "Verificar tarjeta",
      withdraw: "Retirar fondos",
    },
  },
  nl: {
    translation: {
      ExpiresEnd: "Vervaldatum",
      cardHolderLabel: "Naam van de kaarthouder",
      cardHolderPlaceHolder: "Naam op de kaart",
      cardPanLabel: "Kaartnummer",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Vervaldatum",
      CardExpiryPlaceholder: "MM/YY",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "Eenmalig Wachtwoord",
      Password: "Wachtwoord",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Bedrag",
      AmountPlaceholder: "Voer het bedrag in",
      ButtonSubmitLabel: "BETAAL NU",
      SelectPaymentMethodLabel: "Selecteer de betaalwijze",
      SelectPredefineAmountLabel: "Selecteer een bedrag",
      ManualAmountLabel: "Gebruik een ander bedrag",
      customerCitizenshipCountryCodeLabel: "Burgerschap",
      customerCitizenshipCountryCodePlaceHolder: "Land van burgerschap",
      billingCountryCodeLabel: "Land",
      billingCountryCodePlaceHolder: "Selecteer land",
      customerFirstNameLabel: "Voornaam",
      customerFirstNamePlaceHolder: "Voornaam",
      customerLastNameLabel: "Achternaam",
      customerLastNamePlaceHolder: "Achternaam",
      billingAddressLine1Label: "Adresregel 1",
      billingAddressLine1PlaceHolder: "Adresregel 1",
      billingCityLabel: "Stad",
      billingCityPlaceHolder: "Stad",
      billingStateLabel: "Staat",
      billingStatePlaceHolder: "Staat",
      billingPostalCodeLabel: "Postcode",
      billingPostalCodePlaceHolder: "Postcode",
      customerDateOfBirthLabel: "Geboortedatum",
      customerDateOfBirthPlaceHolder: "Geboortedatum",
      customerEmailLabel: "E-mail",
      customerEmailPlaceHolder: "Voer uw e-mailadres in",
      customerPhoneLabel: "Telefoon",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "Persoonlijke identificatie",
      customerPersonalIdPlaceHolder: "Identificatienummer",
      billingAddressLine2Label: "Adresregel 2",
      billingAddressLine2PlaceHolder: "Adresregel 2",
      CardNumberRequired: "Kaartnummer is vereist",
      CardNumberInvalid: "Ongeldig kaartnummer",
      CardExpiryDateRequired: "Vervaldatum is vereist",
      CVVRequired: "Beveiligingscode is vereist",
      customerAccountNumberLabel: "Rekeningnummer",
      customerAccountNumberPlaceHolder: "Voer uw account in",
      AmountRequiredLabel: "Bedrag is vereist",
      AmountInvalidLabel: "Bedrag is ongeldig",
      CancelLabel: "Annuleren",
      PhoneNumberRequired: "Telefoonnummer is vereist",
      PhoneNumberInvalid: "Telefoonnummer is ongeldig",
      PaymentMethodNotAvailableLabel: "Betaalmethoden niet beschikbaar",
      ButtonConfirmLabel: "Bevestigen",
      BASIC_CARD: "Creditcard",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Betaal met uw bank",
      VOLT: "Betaal met uw bank",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Betaal met uw bank",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Crypto deposit",
      ConfirmationMessage: "Bevestig het omgerekende bedrag.",
      customSelectPlaceHolderpaymentMethod: "Selecteer methode",
      customSelectPlaceHolderdocumentType: "Selecteer documenttype",
      customSelectPlaceHoldercryptoCurrency: "Selecteer crypto-valuta",
      blockchainValuePlaceHolder: "Selecteer de blockchain-valuta",
      paymentMethod: "Betaalmethode",
      documentType: "Documenttype",
      documentNumber: "Documentnummer",
      cryptoCurrency: "Crypto-valuta",
      accountNumber: "Rekeningnummer",
      maskedPanMessage: "Bedrag wordt afgetrokken van:",
      endingIn: "eindigt op",
      descriptionLabel: "Beschrijving:",
      invalidCVV: "Ongeldige CVV",
      invalidOTP: "Ongeldige OTP",
      OTPRequired: "OTP is vereist",
      PasswordRequired: "Wachtwoord is vereist",
      invalidPassword: "Ongeldig wachtwoord",
      CardExpiryDateInvalid: "Ongeldige of verlopen datum",
      IN_PROGRESS: "IN UITVOERING",
      CANCELLED: "GEANNULEERD",
      DECLINED: "GEWEIGERD",
      COMPLETED: "VOLTOOID",
      backToShopButtonLabel: "Terug naar de website",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Stortingsbedrag:",
      debitAmountLabel: "Debetbedrag:",
      validationRequired: "Het veld {{ fieldName }} is vereist",
      validationMaxLength: "De maximale lengte is { { maxLength }} tekens",
      cryptoWarningMessage_1:
        "Als u een ander bedrag verzendt, accepteert het systeem het toch!",
      sendAmountInToAddress:
        "Verzend {{ bedrag }} in één betaling naar het volgende adres: {{ adres }}",
      copyToClipboard: "Kopieer adres naar klembord",
      copied: "Gekopieerd",
      scanAddressOr: "Scan het portemonnee-adres of",
      amountToPay: "Te betalen",
      commissionLabel: "Commissie:",
      walletDepositMessage:
        "Gebruik het volgende adres om de storting te voltooien:",
      ButtonConfirmCascadingLabel:
        "Probeer het opnieuw met een andere provider",
      ButtonConfirmCascadingTimeoutLabel: "Zal een andere provider proberen",
      cascadingInstructions:
        "Uw transactie is geweigerd, maar maak u geen zorgen, want we hebben een andere provider om mee te proberen. Sluit deze pagina niet.",
      errorCode:
        "Geachte klant, helaas is er geen beschikbare betalingsoptie voor dit scenario. Zorg ervoor dat de volgende params worden ondersteund: Kaarttype, BIN-land, bedrag en valuta Neem contact op met de handelaar of probeer een andere kaart te gebruiken.",
      TERMINAL_NOT_FOUND: `Beste klant, helaas is er geen betaalmogelijkheid beschikbaar voor dit scenario. Gebruik alstublieft een andere betaalmethode of een andere kaart.`,
      saveCardLabel: "Wil je de kaart opslaan?",
      addNewCard: "Voeg een nieuwe kaart toe",
      accept: "Accepteren",
      validationInvalidCharactersInName:
        "Gebruik ten minste twee Latijnse symbolen",
      validationExactlyTwoWordsRequiredInName:
        "De naam van de kaarthouder moet bestaan uit twee tot vier woorden met ten minste twee letters.",
      validationNotBlank: "Het veld {{ veldnaam }} kan niet leeg zijn",
      receiptBtn: "Ontvang ontvangstbewijs",
      progress:
        "Even geduld, uw transactie wordt verwerkt. Sluit deze pagina niet",
      verifyCard: "Verifieer kaart",
      withdraw: "Terugtrekken",
    },
  },
  de: {
    translation: {
      ExpiresEnd: "Ablaufdatum",
      cardHolderLabel: "Name des Karteninhabers",
      cardHolderPlaceHolder: "Name auf der Karte",
      cardPanLabel: "Kartennummer",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Ablaufdatum",
      CardExpiryPlaceholder: "MM/YY",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "Einmalpasswort",
      Password: "Passwort",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Menge",
      AmountPlaceholder: "Betrag eingeben",
      ButtonSubmitLabel: "JETZT BEZAHLEN",
      SelectPaymentMethodLabel: "Zahlungsmethode auswählen",
      SelectPredefineAmountLabel: "Betrag auswählen",
      ManualAmountLabel: "Verwenden Sie einen anderen Betrag",
      customerCitizenshipCountryCodeLabel: "Staatsbürgerschaft",
      customerCitizenshipCountryCodePlaceHolder: "Land der Staatsangehörigkeit",
      billingCountryCodeLabel: "Land",
      billingCountryCodePlaceHolder: "Land auswählen",
      customerFirstNameLabel: "Vorname",
      customerFirstNamePlaceHolder: "Vorname",
      customerLastNameLabel: "Nachname",
      customerLastNamePlaceHolder: "Nachname",
      billingAddressLine1Label: "Adresse Zeile 1",
      billingAddressLine1PlaceHolder: "Adresse Zeile 1",
      billingCityLabel: "Stadt",
      billingCityPlaceHolder: "Stadt",
      billingStateLabel: "Bundesland",
      billingStatePlaceHolder: "Bundesland",
      billingPostalCodeLabel: "Postleitzahl",
      billingPostalCodePlaceHolder: "Postleitzahl",
      customerDateOfBirthLabel: "Geburtsdatum",
      customerDateOfBirthPlaceHolder: "Geburtsdatum",
      customerEmailLabel: "E-Mail",
      customerEmailPlaceHolder: "Geben Sie Ihre E-Mail-Adresse ein",
      customerPhoneLabel: "Telefon",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "Persönliche Identifikation",
      customerPersonalIdPlaceHolder: "Identifikationsnummer",
      billingAddressLine2Label: "Adresse Zeile 2",
      billingAddressLine2PlaceHolder: "Adresse Zeile 2",
      CardNumberRequired: "Kartennummer ist erforderlich",
      CardNumberInvalid: "Ungültige Kartennummer",
      CardExpiryDateRequired: "Ablaufdatum ist erforderlich",
      CVVRequired: "Der Sicherheitscode ist erforderlich",
      customerAccountNumberLabel: "Kontonummer",
      customerAccountNumberPlaceHolder: "Geben Sie Ihr Konto",
      AmountRequiredLabel: "Betrag ist erforderlich",
      AmountInvalidLabel: "Betrag ist ungültig",
      CancelLabel: "Abbrechen",
      PhoneNumberRequired: "Telefonnummer ist erforderlich",
      PhoneNumberInvalid: "Die Telefonnummer ist ungültig",
      PaymentMethodNotAvailableLabel: "Nicht verfügbare Zahlungsmethoden",
      ButtonConfirmLabel: "Bestätigen",
      BASIC_CARD: "Kreditkarte",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Bezahlen Sie mit Ihrer Bank",
      VOLT: "Bezahlen Sie mit Ihrer Bank",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Bezahlen Sie mit Ihrer Bank",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Crypto deposit",
      ConfirmationMessage: "Bitte bestätigen Sie den umgerechneten Betrag.",
      customSelectPlaceHolderpaymentMethod: "Select -Methode",
      customSelectPlaceHolderdocumentType: "Wählen Sie einen Dokumenttyp",
      customSelectPlaceHoldercryptoCurrency: "Wählen Sie die Kryptowährung aus",
      blockchainValuePlaceHolder: "Wählen Sie den Blockchain-Wert aus",
      paymentMethod: "Zahlungsmethode",
      documentType: "Dokumententyp",
      documentNumber: "Dokumentnummer",
      cryptoCurrency: "Kryptowährung",
      accountNumber: "Kontonummer",
      maskedPanMessage: "Betrag wird abgezogen von :",
      endingIn: "Endet in",
      descriptionLabel: "Beschreibung:",
      invalidCVV: "Ungültiger CVV",
      invalidOTP: "Ungültiger OTP",
      OTPRequired: "OTP ist erforderlich",
      PasswordRequired: "Passwort ist erforderlich",
      invalidPassword: "Ungültiges Passwort",
      CardExpiryDateInvalid: "Ungültiges oder abgelaufenes Datum",
      IN_PROGRESS: "IN BEARBEITUNG",
      CANCELLED: "STORNIERT",
      DECLINED: "ABGELEHNT",
      COMPLETED: "ABGESCHLOSSEN",
      backToShopButtonLabel: "Zurück zur Website",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Einzahlungsbetrag:",
      debitAmountLabel: "Belastungsbetrag:",
      validationRequired: "Das Feld {{fieldName}} ist erforderlich",
      validationMaxLength: "Die maximale Länge beträgt {{maxLength}} Zeichen",
      cryptoWarningMessage_1:
        "Wenn Sie einen anderen Betrag senden, akzeptiert das System ihn trotzdem!",
      sendAmountInToAddress:
        "Senden Sie {{amount}} in einer Zahlung an die folgende Adresse: {{address}}",
      copyToClipboard: "Adresse in die Zwischenablage kopieren",
      copied: "Kopiert",
      scanAddressOr: "Scannen Sie die Wallet-Adresse oder",
      amountToPay: "Zu zahlen",
      commissionLabel: "Kommission:",
      walletDepositMessage:
        "Bitte fahren Sie mit der Einzahlung fort, indem Sie die folgende Adresse verwenden:",
      ButtonConfirmCascadingLabel:
        "Versuchen Sie es erneut mit einem anderen Anbieter",
      ButtonConfirmCascadingTimeoutLabel:
        "Wird einen anderen Anbieter ausprobieren in ",
      cascadingInstructions:
        "Ihre Transaktion wurde abgelehnt, aber keine Sorge, da wir einen anderen Anbieter zum Ausprobieren haben. Bitte schließen Sie diese Seite nicht.",
      errorCode:
        "Sehr geehrter Kunde, leider gibt es für dieses Szenario keine Zahlungsoption. Bitte stellen Sie sicher, dass die folgenden Parameter unterstützt werden: Kartentyp, BIN Land, Betrag und Währung Bitte kontaktieren Sie den Händler oder versuchen Sie, eine andere Karte zu verwenden.",
      TERMINAL_NOT_FOUND: `Sehr geehrter Kunde, leider gibt es für dieses Szenario keine verfügbare Zahlungsmöglichkeit. Bitte verwenden Sie eine andere Zahlungsmethode oder eine andere Karte.`,
      saveCardLabel: "Möchten Sie die Karte speichern?",
      addNewCard: "Neue Karte hinzufügen",
      accept: "Akzeptieren",
      validationInvalidCharactersInName:
        "Bitte verwenden Sie mindestens zwei lateinische Symbole",
      validationExactlyTwoWordsRequiredInName:
        "Der Name des Karteninhabers muss aus zwei bis vier Wörtern mit mindestens zwei Buchstaben bestehen",
      validationNotBlank: "Das Feld {{fieldName}} kann nicht leer sein",
      receiptBtn: "Quittung erhalten",
      progress:
        "Bitte warten Sie, um Ihre Transaktion zu bearbeiten. Schließen Sie diese Seite nicht",
      verifyCard: "Karte verifizieren",
      withdraw: "Auszahlung",
    },
  },
  ar: {
    translation: {
      ExpiresEnd: "تاريخ انتهاء الصلاحية",
      cardHolderLabel: "اسم صاحب البطاقة",
      cardHolderPlaceHolder: "الاسم على البطاقة",
      cardPanLabel: "رقم البطاقة",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "تاريخ انتهاء الصلاحية",
      CardExpiryPlaceholder: "شهر/سنة",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "كلمة مرور لمرة واحدة",
      Password: "كلمة مرور",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "المبلغ",
      AmountPlaceholder: "أدخل المبلغ",
      ButtonSubmitLabel: "ادفع الآن",
      SelectPaymentMethodLabel: "اختر طريقة الدفع",
      SelectPredefineAmountLabel: "اختر المبلغ",
      ManualAmountLabel: "استخدم مبلغًا مختلفًا",
      customerCitizenshipCountryCodeLabel: "الجنسية",
      customerCitizenshipCountryCodePlaceHolder: "بلد الجنسية",
      billingCountryCodeLabel: "بلد",
      billingCountryCodePlaceHolder: "اختر البلد",
      customerFirstNameLabel: "الاسم الأول",
      customerFirstNamePlaceHolder: "الاسم الأول",
      customerLastNameLabel: "الاسم الأخير",
      customerLastNamePlaceHolder: "الاسم الأخير",
      billingAddressLine1Label: "العنوان سطر 1",
      billingAddressLine1PlaceHolder: "العنوان سطر 1",
      billingCityLabel: "مدينة",
      billingCityPlaceHolder: "مدينة",
      billingStateLabel: "ولاية",
      billingStatePlaceHolder: "ولاية",
      billingPostalCodeLabel: "الرمز البريدي",
      billingPostalCodePlaceHolder: "الرمز البريدي",
      customerDateOfBirthLabel: "تاريخ الولادة",
      customerDateOfBirthPlaceHolder: "تاريخ الولادة",
      customerEmailLabel: "البريد الإلكتروني",
      customerEmailPlaceHolder: "أدخل بريدك الإلكتروني",
      customerPhoneLabel: "الهاتف",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "التعريف الشخصي",
      customerPersonalIdPlaceHolder: "رقم التعريف",
      billingAddressLine2Label: "العنوان سطر 2",
      billingAddressLine2PlaceHolder: "العنوان سطر 2",
      CardNumberRequired: "رقم البطاقة مطلوب",
      CardNumberInvalid: "رقم بطاقة غير صالح",
      CardExpiryDateRequired: "تاريخ انتهاء الصلاحية مطلوب",
      CVVRequired: "مطلوب رمز أمني",
      customerAccountNumberLabel: "رقم الحساب",
      customerAccountNumberPlaceHolder: "أدخل حسابك",
      AmountRequiredLabel: "المبلغ مطلوب",
      AmountInvalidLabel: "المبلغ غير صالح",
      CancelLabel: "إلغاء",
      PhoneNumberRequired: "الرقم الهاتفي مطلوب",
      PhoneNumberInvalid: "الرقم الهاتفي غير صالح",
      PaymentMethodNotAvailableLabel: "طرق الدفع غير متاحة",
      ButtonConfirmLabel: "تأكيد",
      BASIC_CARD: "بطاقة ائتمانية",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "ادفع مع البنك الذي تتعامل معه",
      VOLT: "ادفع مع البنك الذي تتعامل معه",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "ادفع مع البنك الذي تتعامل معه",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Crypto deposit",
      ConfirmationMessage: "يرجى تأكيد المبلغ المحول.",
      customSelectPlaceHolderpaymentMethod: "انتقِ طريقة",
      customSelectPlaceHolderdocumentType: "اختر نوع المستند",
      customSelectPlaceHoldercryptoCurrency: "حدد العملة المشفرة",
      blockchainValuePlaceHolder: "اختر قيمة البلوكشين",
      paymentMethod: "طريقة الدفع",
      documentType: "نوع المستند",
      documentNumber: "رقم المستند",
      cryptoCurrency: "العملة المشفرة",
      accountNumber: "رقم الحساب",
      maskedPanMessage: "سيخصم المبلغ من:",
      endingIn: "المنتهية في",
      descriptionLabel: "الوصف:",
      invalidCVV: "CVV غير صالح",
      invalidOTP: "OTP غير صالح",
      OTPRequired: "مطلوب OTP",
      PasswordRequired: "كلمة المرور مطلوبة",
      invalidPassword: "كلمة مرور غير صالحة",
      CardExpiryDateInvalid: "تاريخ غير صالح أو منتهي الصلاحية",
      IN_PROGRESS: "قيد التقدم",
      CANCELLED: "ملغاة",
      DECLINED: "رُفِضَ",
      COMPLETED: "انتهاء",
      backToShopButtonLabel: "العودة إلى الموقع الإلكتروني",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "مبلغ الإيداع:",
      debitAmountLabel: "Debit مبلغ:",
      validationRequired: "الحقل {{fieldName}} مطلوب",
      validationMaxLength: "الطول الأقصى هو {{maxLength}} حرفاً",
      cryptoWarningMessage_1:
        "إذا قمت بإرسال أي مبلغ آخر، فسيقوم النظام بقبوله على أي حال!",
      sendAmountInToAddress:
        "أرسل {{المبلغ}} في دفعة واحدة إلى العنوان التالي: {{العنوان}}",
      copyToClipboard: "نسخ العنوان إلى حافظة",
      copied: "تم نسخه",
      scanAddressOr: "مسح عنوان المحفظة أو",
      amountToPay: "لدفع",
      commissionLabel: "اللجنة:",
      walletDepositMessage: "يرجى الشروع في الإيداع باستخدام العنوان التالي:",
      ButtonConfirmCascadingLabel: "حاول مرة أخرى باستخدام مزود آخر",
      ButtonConfirmCascadingTimeoutLabel: "سوف تجرب مزود آخر في ",
      cascadingInstructions:
        "تم رفض صفقتك، لكن لا تقلق لأن لدينا مزودًا آخر نجربه. من فضلك، لا تغلق هذه الصفحة.",
      errorCode:
        "عزيزي العميل، لسوء الحظ، لا يوجد خيار دفع متاح لهذا السيناريو. يرجى التأكد من دعم البارامات التالية: نوع البطاقة، بلد BIN، المبلغ والعملة يرجى الاتصال بالتاجر أو محاولة استخدام بطاقة أخرى.",
      TERMINAL_NOT_FOUND: `عزيزي العميل، للأسف، لا توجد طريقة دفع متاحة لهذا السيناريو. يُرجى استخدام طريقة دفع أخرى أو بطاقة أخرى.`,
      saveCardLabel: "هل تريد حفظ البطاقة؟",
      addNewCard: "أضف بطاقة جديدة",
      accept: "قبول",
      validationInvalidCharactersInName:
        "يرجى استخدام رمزين لاتينيين على الأقل",
      validationExactlyTwoWordsRequiredInName:
        "يجب أن يتألف اسم حامل البطاقة من كلمتين إلى أربع كلمات من حرفين على الأقل",
      validationNotBlank: "حقل {{fieldName}} لا يمكن أن يكون فارغًا",
      receiptBtn: "احصل على الإيصال",
      progress: "من فضلك انتظر، معالجة صفقتك. لا تغلق هذه الصفحة",
      verifyCard: "تحقق من البطاقة",
      withdraw: "سحب",
    },
  },
  pt: {
    translation: {
      ExpiresEnd: "Data de expiração",
      cardHolderLabel: "Nome do titular",
      cardHolderPlaceHolder: "Nome no cartão",
      cardPanLabel: "Número do cartão",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Data de expiração",
      CardExpiryPlaceholder: "MM/AA",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "Senha única",
      Password: "Senha",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Valor",
      AmountPlaceholder: "Insira o valor",
      ButtonSubmitLabel: "PAGAR AGORA",
      SelectPaymentMethodLabel: "Selecione o método de pagamento",
      SelectPredefineAmountLabel: "Selecione um valor",
      ManualAmountLabel: "Use um valor diferente",
      customerCitizenshipCountryCodeLabel: "Cidadania",
      customerCitizenshipCountryCodePlaceHolder: "País de cidadania",
      billingCountryCodeLabel: "País",
      billingCountryCodePlaceHolder: "Selecione o país",
      customerFirstNameLabel: "Primeiro nome",
      customerFirstNamePlaceHolder: "Primeiro nome",
      customerLastNameLabel: "Último nome",
      customerLastNamePlaceHolder: "Último nome",
      billingAddressLine1Label: "Endereço linha 1",
      billingAddressLine1PlaceHolder: "Endereço linha 1",
      billingCityLabel: "Cidade",
      billingCityPlaceHolder: "Cidade",
      billingStateLabel: "Estado",
      billingStatePlaceHolder: "Estado",
      billingPostalCodeLabel: "Código postal",
      billingPostalCodePlaceHolder: "Código postal",
      customerDateOfBirthLabel: "Data de nascimento",
      customerDateOfBirthPlaceHolder: "Data de nascimento",
      customerEmailLabel: "Email",
      customerEmailPlaceHolder: "Insira seu email",
      customerPhoneLabel: "Telefone",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "Identificação pessoal",
      customerPersonalIdPlaceHolder: "Número de identificação",
      billingAddressLine2Label: "Endereço linha 2",
      billingAddressLine2PlaceHolder: "Endereço linha 2",
      CardNumberRequired: "Número do cartão é obrigatório",
      CardNumberInvalid: "Número do cartão inválido",
      CardExpiryDateRequired: "Data de expiração é obrigatória",
      CVVRequired: "Código de segurança é obrigatório",
      customerAccountNumberLabel: "Número da conta",
      customerAccountNumberPlaceHolder: "Insira sua conta",
      AmountRequiredLabel: "O valor é obrigatório",
      AmountInvalidLabel: "Valor inválido",
      CancelLabel: "Cancelar",
      PhoneNumberRequired: "Número de telefone é obrigatório",
      PhoneNumberInvalid: "Número de telefone inválido",
      PaymentMethodNotAvailableLabel: "Métodos de pagamento não disponíveis",
      ButtonConfirmLabel: "Confirmar",
      BASIC_CARD: "Cartão de Crédito",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Pagamento Local",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Pague com seu banco",
      VOLT: "Pague com seu banco",
      B2BINPAY: "B2BinPay (Cripto)",
      B2BINPAYV2: "B2BinPay (Cripto)",
      CLICK: "Pague com seu banco",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Depósito em cripto",
      ConfirmationMessage: "Por favor, confirme o valor convertido.",
      customSelectPlaceHolderpaymentMethod: "Selecione o método",
      customSelectPlaceHolderdocumentType: "Selecione o tipo de documento",
      customSelectPlaceHoldercryptoCurrency: "Selecione a criptomoeda",
      blockchainValuePlaceHolder: "Selecione o valor da blockchain",
      paymentMethod: "Método de pagamento",
      documentType: "Tipo de documento",
      documentNumber: "Número do documento",
      cryptoCurrency: "Criptomoeda",
      accountNumber: "Número da conta",
      maskedPanMessage: "O valor será deduzido de:",
      endingIn: "terminando em",
      descriptionLabel: "Descrição:",
      invalidCVV: "CVV inválido",
      invalidOTP: "OTP inválido",
      OTPRequired: "OTP é obrigatório",
      PasswordRequired: "Senha é obrigatória",
      invalidPassword: "Senha inválida",
      CardExpiryDateInvalid: "Data inválida ou expirada",
      IN_PROGRESS: "EM PROGRESSO",
      CANCELLED: "CANCELADO",
      DECLINED: "RECUSADO",
      COMPLETED: "COMPLETO",
      backToShopButtonLabel: "Retornar ao site",
      minAmountLabel: "Mín:",
      maxAmountLabel: "Máx:",
      depositAmountLabel: "Valor do depósito:",
      debitAmountLabel: "Valor de débito:",
      validationRequired: "O campo {{fieldName}} é obrigatório",
      validationMaxLength: "O comprimento máximo é de {{maxLength}} caracteres",
      cryptoWarningMessage_1:
        "Se você enviar qualquer outro valor, o sistema o aceitará de qualquer maneira!",
      sendAmountInToAddress:
        "Envie {{amount}} em um único pagamento para o seguinte endereço: {{address}}",
      copyToClipboard: "Copiar endereço para a área de transferência",
      copied: "Copiado",
      scanAddressOr: "Escaneie o endereço da carteira ou",
      amountToPay: "A pagar",
      commissionLabel: "Comissão:",
      walletDepositMessage:
        "Por favor, prossiga com o depósito usando o seguinte endereço:",
      ButtonConfirmCascadingLabel: "Tente novamente usando outro provedor",
      ButtonConfirmCascadingTimeoutLabel: "Tentará outro provedor em",
      cascadingInstructions:
        "Sua transação foi recusada, mas não se preocupe, temos outro provedor para tentar. Por favo, não feche esta página.",
      errorCode:
        "Caro cliente, infelizmente, não há opção de pagamento disponível para este cenário. Certifique-se de que os seguintes parâmetros sejam suportados: Tipo de Cartão, País do BIN, Valor e Moeda. Por favor, contate o comerciante ou tente usar outro cartão.",
      TERMINAL_NOT_FOUND:
        "Prezado cliente, Infelizmente não há opção de pagamento disponível para este cenário. Utilize outro método de pagamento ou outro cartão.",
      saveCardLabel: "Deseja salvar o cartão?",
      addNewCard: "Adicionar novo cartão",
      accept: "Aceitar",
      validationInvalidCharactersInName:
        "Por favor, use pelo menos dois símbolos latinos",
      validationExactlyTwoWordsRequiredInName:
        "O nome do titular do cartão deve consistir em exatamente duas palavras",
      validationNotBlank: "O campo {{fieldName}} não pode estar em branco",
      receiptBtn: "Obter recibo",
      progress:
        "Por favor, aguarde, processando sua transação. Não feche esta página",
      verifyCard: "Verificar cartão",
      withdraw: "Retirar",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
